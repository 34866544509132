/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-31 11:17:08
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-31 11:24:14
 */

import http from "./axios_init.js";

export default {
  /**
   * 获取所有的文件夹
   * @returns
   */
  get_folders() {
    return http.post("seller/v1/psd/folder");
  },
};
